<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Виза')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/visa`
    "
    :prefixShow="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/visa`
    "
    :linkCreate="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/visa/create`
    "
    :keyTable="'id'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="alternativeConverter"
    :isDefaultConverter="false"
    :hasReadOnlyInTable="hasReadOnlyInTable"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageTable from "../../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../../constant";
import formatData from "../../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "PersonContact",
  components: { CommonPageTable },
  props: {
    hasReadOnlyInTable: { type: Boolean, default: true }
  },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_VISA,
      apiDeleteName: API_METHODS.DELETE_VISA
    };
  },
  computed: {
    paramsGetData() {
      return { PersonId: this.$route.params.PersonId };
    },
    nameRoleEntity() {
      return NAME_OBJECT.visa;
    }
  },
  methods: {
    deleteParams(id) {
      return { PersonId: this.$route.params.PersonId, id: id };
    },
    alternativeConverter(dataNow, langNow) {
      if (dataNow && dataNow.length > 0) {
        let tempdataNow = convertLangDataToTable(dataNow, langNow, "", false);
        tempdataNow = tempdataNow.map(item => {
          return {
            ...item,
            validFrom: formatData(item.validFrom),
            validTo: formatData(item.validTo)
          };
        });
        return tempdataNow;
      }

      return [];
    }
  }
};
</script>
