<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Контакты')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/contact`
    "
    :prefixShow="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/contact`
    "
    :linkCreate="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/contact/create`
    "
    :keyTable="'id'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageTable from "../../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../../constant";
export default {
  name: "PersonContact",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_CONTACT,
      apiDeleteName: API_METHODS.DELETE_CONTACT
    };
  },
  computed: {
    paramsGetData() {
      return { SubjectId: this.$route.params.PersonId };
    },
    nameRoleEntity() {
      return NAME_OBJECT.clientPersonContact;
    }
  },
  methods: {
    deleteParams(id) {
      return { SubjectId: this.$route.params.PersonId, id: id };
    }
  }
};
</script>
