<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Предпочтения')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="`/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/pref`"
    :prefixShow="`/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/pref`"
    :linkCreate="`/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/pref/create`"
               
    :keyTable="'id'"
    :alternativeConverter="alternativeConverter"
    :isDefaultConverter="false"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :hasReadOnlyInTable="hasReadOnlyInTable" 
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageTable from "../../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../../constant";
import formatData from "../../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "PersonPrefData",
  components: { CommonPageTable },
  props:{
      hasReadOnlyInTable: { type: Boolean, default: true }, 
  },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_PREF_DATA,
      apiDeleteName: API_METHODS.DELETE_PREF_DATA
    };
  },
  computed: {
    paramsGetData() {
      return { PersonId: this.$route.params.PersonId };
    },
    nameRoleEntity() {
      return NAME_OBJECT.personePref;
    }
  },
  methods: {
    deleteParams(id) {
      return { PersonId:  this.$route.params.PersonId,  PrefId: id };
    },
    alternativeConverter(dataNow, langNow) {
      if (dataNow && dataNow.length > 0) {
        let tempdataNow = convertLangDataToTable(dataNow, langNow, "", false);
        tempdataNow = tempdataNow.map(item => {
          return { ...item, isActive: item.isActive?this.$t('Активна'):this.$t("Не активна"), validDate :  formatData(item.validDate) };
        });
        return tempdataNow;
      }

      return [];
    }
  }
};
</script>
