<template>
  <el-dialog
    center
    @open="initData"
    :title="$t('История изменений')"
    :visible.sync="modals[nameModal]">
    <table-history :tableData="loadData"
                   :fieldInfoList = "fieldInfoList"
                   :load="load"
                   :updateData="updateData"
                   :searchStart="searchStart"
                   :sortData="sortData"
                   :setSort="demo"
                   :backData="backData"
                   configName = "clientPerson"
    >
    </table-history>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="toggleModal(nameModal)">Закрыть</button>
            </span>
  </el-dialog>
</template>
<script>
  import { HISTORY_PERSON, RETURN_PERSON } from "./store/type";
  import {historyModal} from "../../../mixins/history/HistoryModal";

  export default {
    name: 'HistoryPerson',
    mixins: [historyModal],
    data : function () {
      return {
        history : HISTORY_PERSON,
        return : RETURN_PERSON,
        paramsReturn : {
          CompanyId: this.$route.params.CompanyId,
          ContractId: this.$route.params.ContractId,
        },
        paramsGet : {
          CompanyId : this.$route.params.CompanyId,
          ContractId: this.$route.params.ContractId,
          PersonId: this.$route.params.PersonId,
          self: this
        }
      }
    },
  }
</script>
