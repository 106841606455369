<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Бонусные карты')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/bonusCard`
    "
    :prefixShow="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/bonusCard`
    "
    :linkCreate="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/bonusCard/create`
    "
    :keyTable="'id'"
    :alternativeConverter="alternativeConverter"
    :isDefaultConverter="false"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageTable from "../../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../../constant";
import formatData from "../../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "TablePageAttrEntity",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_BONUS_CARD,
      apiDeleteName: API_METHODS.DELETE_BONUS_CARD
    };
  },
  computed: {
    paramsGetData() {
      return { PersonId: this.$route.params.PersonId };
    },
    nameRoleEntity() {
      return NAME_OBJECT.clientPersonBonusCard;
    }
  },
  methods: {
    deleteParams(id) {
      return { PersonId: this.$route.params.PersonId, BonusCardId: id };
    },
    alternativeConverter(dataNow, langNow) {
      if (dataNow && dataNow.length > 0) {
        let tempdataNow = convertLangDataToTable(dataNow, langNow, "", false);
        tempdataNow = tempdataNow.map(item => {
          return {
            ...item,
            isActive: item.isActive
              ? this.$t("Активна")
              : this.$t("Не активна"),
            validDate: formatData(item.validDate)
          };
        });
        return tempdataNow;
      }

      return [];
    }
  }
};
</script>
