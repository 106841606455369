<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Коды персоны')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/personcodevalue`
    "
    :prefixShow="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/personcodevalue`
    "
    :linkCreate="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/personcodevalue/create`
    "
    :keyTable="'codeId'"
    :alternativeConverter="alternativeConverter"
    :isDefaultConverter="false"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :hasReadOnlyInTable="hasReadOnlyInTable"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "TablePageAttrEntity",
  components: { CommonPageTable },
  props: {
    hasReadOnlyInTable: { type: Boolean, default: true }
  },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_PERSONE_CODE_VALUE,
      apiDeleteName: API_METHODS.DELETE_PERSONE_CODE_VALUE,
      routeField: "PersonId"
    };
  },
  computed: {
    paramsGetData() {
      return { PersonId: this.$route.params[this.routeField] };
    },
    nameRoleEntity() {
      return NAME_OBJECT.personCodeValue;
    }
  },
  methods: {
    deleteParams(id, row) {
      return {
        id: row.codePersonValueId,
        PersonId: this.$route.params[this.routeField]
      };
    },
    alternativeConverter(dataNow, langNow) {
      if (dataNow && dataNow.length > 0) {
        let tempdataNow = convertLangDataToTable(dataNow, langNow, "", false);
        return tempdataNow;
      }
      return [];
    }
  }
};
</script>
